.wrapper {
    border-top: 1px solid #0D4C66;
    background: #122932;
    display: flex;
    justify-content: center;
}

.content {
    width: 1440px;
    padding: 40px 100px 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.content_small {
    width: 1440px;
    padding: 40px 100px 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
}

.footer_block {
    width: 295px;
    margin-bottom: 24px;
}

.title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.license_number {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.19em;
    color: #FFFFFF;
    margin-left: 5px;
    margin-bottom: 8px;
}

.mwali_link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #009DE0;
    text-decoration: none;
}

.docs_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.doc_link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #009DE0;
    margin-bottom: 16px;
    text-decoration: none;
}

.links_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer_link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FAFAFA;
    transition: .5s all ease;
    text-decoration: none;
    margin-bottom: 16px;
    position: relative;
}

.footer_link:hover:after {
    width: 100%;
    content: "";
    bottom: -2px;
    left: 0;
    height: 2px;
    background: #009DE0;
    position: absolute;
}

.info_block {

}

.mail_block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.address_block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 12px;
    max-width: 280px;
}


.mail_block img {
    margin-right: 12px;
}

.mail {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FAFAFA;
    text-decoration: none;
}

.swift_block {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FAFAFA;
    margin-bottom: 16px;
}

.whatsapp_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FAFAFA;
}

.whatsapp_wrapper {
    width: 38px;
    height: 38px;
    background: #009DE0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s all ease;
    border: 1px solid #009DE0;
    margin-right: 20px;
    margin-bottom: 0;
}

.whatsapp_wrapper:hover {
    transition: .5s all ease;
    background: #0D4C66;
    border: 1px solid #009DE0;
    width: 38px;
    height: 38px;
}

.copyright {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: #0D4C66;
}

.content_small_mobile {
    display: none;
}

.flex {
    display: flex;
    align-items: center;
    margin-bottom: 118px;
}

.telegrammLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen
and (max-device-width: 768px) {

    .content_small {
        display: none;
    }

    .content {
        display: none;
    }

    .content_small_mobile {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }

    .copyright {
        text-align: center;
        padding-top: 24px;
    }
}
